import React from "react";

const Typography = () => (
  <>
    <h1>h1 heading</h1>
    <h2>h2 heading</h2>
    <h3>h3 heading</h3>
    <h4>h4 heading</h4>
    <h5>h5 heading</h5>
    <h6>h6 heading</h6>
    <p>Paragraphs size in this line</p>
    <small>Small text paraghraph</small>
    <p className="u-text--detail">Text detail</p>
    <p className="u-text--desc">Text description | Ieri | 6 commenti</p>
  </>
);

export default Typography;
